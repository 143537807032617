import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="monsters-vermin"></a><h2>Monsters Vermin</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">In Alphabetical Order</span></h2>
    <table className="monster-index" border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="top">
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#giant-ant">Giant Ant</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#giant-bee">Giant
Bee</a>
          </td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#giant-bombardier-beetle">Giant Bombardier Beetle</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#giant-fire-beetle">Giant Fire Beetle</a></td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#giant-stag-beetle">Giant Stag Beetle</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#giant-praying-mantis">Giant Praying Mantis</a></td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#giant-wasp">Giant Wasp</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#monstrous-centipede">Monstrous Centipede</a></td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#monstrous-scorpion">Monstrous Scorpion</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#monstrous-spider">Monstrous Spider</a></td>
          <td style={{
            "width": "12%"
          }}>
          </td>
        </tr>
      </tbody>
    </table>
    <p>These creatures operate on instinct, driven by simple needs
such as food and reproduction. Except where noted, vermin attack only
when hungry or threatened.</p>
    <p><b>Vermin Traits</b>: Vermin possess the following traits
(unless otherwise noted in a creature&rsquo;s entry).</p>
    <p>&mdash;Mindless: No Intelligence score, and immunity to all
mind-affecting effects (charms, compulsions, phantasms, patterns, and
morale effects).</p>
    <p>&mdash;Darkvision out to 60 feet.</p>
    <p>&mdash;Alignment: Always neutral. Vermin are not governed by a
human
sense of morality.</p>
    <p>&mdash;Treasure: Vermin generally possess no treasure. For
those
that do, this treasure consists of possessions formerly owned by a
creature that the monster has killed.</p>
    <h3><a id="giant-ant"></a>GIANT ANT</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "16%"
          }}>
          </th>
          <th style={{
            "width": "27%"
          }}>Giant Ant, Worker
Medium Vermin</th>
          <th style={{
            "width": "2%"
          }}>
          </th>
          <th style={{
            "width": "27%"
          }}>Giant Ant, Soldier
Medium Vermin</th>
          <th style={{
            "width": "2%"
          }}>
          </th>
          <th style={{
            "width": "27%"
          }}>Giant Ant, Queen
Large Vermin</th>
        </tr>
        <tr className="odd-row">
          <td><b>Hit Dice:</b></td>
          <td>2d8 (9 hp)</td>
          <td>
          </td>
          <td>2d8+2 (11 hp)</td>
          <td>
          </td>
          <td>4d8+4 (22 hp)</td>
        </tr>
        <tr>
          <td><b>Initiative:</b></td>
          <td>+0</td>
          <td>
          </td>
          <td>+0</td>
          <td>
          </td>
          <td>&ndash;1</td>
        </tr>
        <tr className="odd-row">
          <td><b>Speed:</b></td>
          <td>50 ft. (10 squares), climb 20 ft.</td>
          <td>
          </td>
          <td>50 ft. (10 squares), climb 20 ft.</td>
          <td>
          </td>
          <td>40 ft. (8 squares)</td>
        </tr>
        <tr>
          <td><b>Armor Class:</b></td>
          <td>17 (+7 natural), touch 10, flat-footed 17</td>
          <td>
          </td>
          <td>17 (+7 natural), touch 10, flat-footed 17</td>
          <td>
          </td>
          <td>17 (&ndash;1 size, &ndash;1 Dex, +9 natural), touch 8,
flat-footed
17</td>
        </tr>
        <tr className="odd-row">
          <td><b>Base Attack/Grapple:</b></td>
          <td>+1/+1</td>
          <td>
          </td>
          <td>+1/+3</td>
          <td>
          </td>
          <td>+3/+10</td>
        </tr>
        <tr>
          <td><b>Attack:</b></td>
          <td>Bite +1 melee (1d6)</td>
          <td>
          </td>
          <td>Bite +3 melee (2d4+3)</td>
          <td>
          </td>
          <td>Bite +5 melee (2d6+4)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Full Attack:</b></td>
          <td>Bite +1 melee (1d6)</td>
          <td>
          </td>
          <td>Bite +3 melee (2d4+3)</td>
          <td>
          </td>
          <td>Bite +5 melee (2d6+4)</td>
        </tr>
        <tr>
          <td><b>Space/Reach:</b></td>
          <td>5 ft./5 ft.</td>
          <td>
          </td>
          <td>5 ft./5 ft.</td>
          <td>
          </td>
          <td>10 ft./5 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><b>Special Attacks:</b></td>
          <td>Improved grab</td>
          <td>
          </td>
          <td>Improved grab, acid sting</td>
          <td>
          </td>
          <td>Improved grab</td>
        </tr>
        <tr>
          <td><b>Special Qualities:</b></td>
          <td>Scent, vermin traits</td>
          <td>
          </td>
          <td>Scent, vermin traits</td>
          <td>
          </td>
          <td>Scent, vermin traits</td>
        </tr>
        <tr className="odd-row">
          <td><b>Saves:</b></td>
          <td>Fort +3, Ref +0, Will +0</td>
          <td>
          </td>
          <td>Fort +4, Ref +0, Will +1</td>
          <td>
          </td>
          <td>Fort +5, Ref +0, Will +2</td>
        </tr>
        <tr>
          <td><b>Abilities:</b></td>
          <td>Str 10, Dex 10, Con 10, Int &mdash;, Wis 11, Cha 9</td>
          <td>
          </td>
          <td>Str 14, Dex 10, Con 13, Int &mdash;, Wis 13, Cha 11</td>
          <td>
          </td>
          <td>Str 16, Dex 9, Con 13, Int &mdash;, Wis 13, Cha 11</td>
        </tr>
        <tr className="odd-row">
          <td><b>Skills:</b></td>
          <td><a href="skillsAll.html#climb" style={{
              "color": "rgb(87, 158, 182)"
            }}>Climb</a> +8</td>
          <td>
          </td>
          <td>Climb +10</td>
          <td>
          </td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td><b>Feats:</b></td>
          <td>TrackB</td>
          <td>
          </td>
          <td>TrackB</td>
          <td>
          </td>
          <td>TrackB</td>
        </tr>
        <tr className="odd-row">
          <td><b>Environment:</b></td>
          <td>Temperate plains</td>
          <td>
          </td>
          <td>Temperate plains</td>
          <td>
          </td>
          <td>Temperate plains</td>
        </tr>
        <tr>
          <td><b>Organization:</b></td>
          <td>Gang (2&ndash;6) or crew (6&ndash;11 plus 1 giant ant
soldier)</td>
          <td>
          </td>
          <td>Solitary or gang (2&ndash;4)</td>
          <td>
          </td>
          <td>Hive (1 plus 10&ndash;100 workers and 5&ndash;20
soldiers)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Challenge Rating:</b></td>
          <td>1</td>
          <td>
          </td>
          <td>2</td>
          <td>
          </td>
          <td>2</td>
        </tr>
        <tr>
          <td><b>Treasure:</b></td>
          <td>None</td>
          <td>
          </td>
          <td>None</td>
          <td>
          </td>
          <td>1/10 coins; 50% goods; 50% items</td>
        </tr>
        <tr className="odd-row">
          <td><b>Advancement:</b></td>
          <td>3&ndash;4 HD (Medium); 5&ndash;6 HD (Large)</td>
          <td>
          </td>
          <td>3&ndash;4 HD (Medium); 5&ndash;6 HD (Large)</td>
          <td>
          </td>
          <td>5&ndash;6 HD (Large); 7&ndash;8 HD (Huge)</td>
        </tr>
        <tr>
          <td className="last-row"><b>Level Adjustment:</b></td>
          <td className="last-row">&mdash;</td>
          <td className="last-row">
          </td>
          <td className="last-row">&mdash;</td>
          <td className="last-row">
          </td>
          <td className="last-row">&mdash;</td>
        </tr>
      </tbody>
    </table>
    <p>Giant ants are among the hardiest and most adaptable vermin.
Soldiers and workers are about 6 feet long, while queens can grow to a
length of 9 feet.</p>
    <p><b>Acid Sting (Ex)</b>: A giant soldier ant has a stinger and
an acid-producing gland in its abdomen. If it successfully grabs an
opponent, it can attempt to sting each round (+3 attack bonus). A hit
with the sting attack deals 1d4+1 points of piercing damage and 1d4
points of acid damage.</p>
    <p><b>Improved Grab (Ex)</b>: To use this ability, a giant ant
must hit with its bite attack. A giant soldier ant that wins the
ensuing grapple check establishes a hold and can sting.</p>
    <p><b>Skills</b>: *Giant ants have a +4 racial bonus on Survival
checks when tracking by scent and a +8 racial bonus on Climb checks. A
giant ant can always choose to take 10 on Climb checks, even if rushed
or threatened.</p>
    <h3><a id="giant-bee"></a>GIANT BEE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <span className="size-block">Medium Vermin</span> <span className="stat-block"><b>Hit Dice</b>: 3d8 (13 hp)</span> <span className="stat-block"><b>Initiative</b>: +2</span> <span className="stat-block"><b>Speed</b>: 20 ft. (4 squares), fly 80
ft. (good)</span> <span className="stat-block"><b>Armor Class</b>: 14 (+2
Dex, +2
natural), touch 12, flat-footed 12</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +2/+2</span> <span className="stat-block"><b>Attack</b>:
Sting +2 melee (1d4 plus
poison)</span> <span className="stat-block"><b>Full Attack</b>: Sting +2
melee (1d4
plus poison)</span> <span className="stat-block"><b>Space/Reach</b>: 5
ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
Poison</span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., vermin traits</span> <span className="stat-block"><b>Saves</b>: Fort
+3, Ref +3, Will +2</span> <span className="stat-block"><b>Abilities</b>:
Str 11, Dex 14, Con
11, Int &mdash;, Wis 12, Cha 9</span> <span className="stat-block"><b>Skills</b>:
Spot +5, Survival +1*</span> <span className="stat-block"><b>Feats</b>:
&mdash;</span> <span className="stat-block"><b>Environment</b>: Temperate
plains</span> <span className="stat-block"><b>Organization</b>: Solitary,
buzz
(2&ndash;5), or hive (11&ndash;20)</span> <span className="stat-block"><b>Challenge
Rating</b>: 1</span> <span className="stat-block"><b>Treasure</b>: No
coins; 1/4 goods
(honey only); no items</span> <span className="stat-block"><b>Advancement</b>:
4&ndash;6 HD (Medium); 7&ndash;9
HD (Large)</span> <span className="stat-block"><b>Level Adjustment</b>:
&mdash;</span>
    <p>Although many times larger, growing to a length of about 5
feet, giant bees behave generally the same as their smaller cousins.</p>
    <p>Giant bees are usually not aggressive except when defending
themselves or their hive.</p>
    <p><b>Poison (Ex)</b>: Injury, Fortitude DC 11, initial and
secondary damage 1d4 Con. The save DC is Constitution-based.</p>
    <p>A giant bee that successfully stings another creature pulls
away, leaving its stinger in the creature. The bee then dies.</p>
    <p><b>Skills</b>: Giant bees have a +4 racial bonus on Spot
checks. *They also have a +4 racial bonus on Survival checks to orient
themselves.</p>
    <h3><a id="giant-bombardier-beetle"></a>GIANT BOMBARDIER BEETLE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <span className="size-block">Medium Vermin</span> <span className="stat-block"><b>Hit Dice</b>: 2d8+4 (13 hp)</span> <span className="stat-block"><b>Initiative</b>: +0</span> <span className="stat-block"><b>Speed</b>: 30 ft. (6 squares)</span> <span className="stat-block"><b>Armor Class</b>: 16 (+6 natural),
touch 10, flat-footed 16</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +1/+2</span> <span className="stat-block"><b>Attack</b>:
Bite +2 melee (1d4+1)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +2 melee (1d4+1)</span> <span className="stat-block"><b>Space/Reach</b>:
5 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
Acid spray</span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., vermin traits</span> <span className="stat-block"><b>Saves</b>: Fort
+5, Ref +0, Will +0</span> <span className="stat-block"><b>Abilities</b>:
Str 13, Dex 10, Con
14, Int &mdash;, Wis 10, Cha 9</span> <span className="stat-block"><b>Skills</b>:
&mdash;</span> <span className="stat-block"><b>Feats</b>: &mdash;</span> <span className="stat-block"><b>Environment</b>: Warm forests</span> <span className="stat-block"><b>Organization</b>: Cluster (2&ndash;5) or
click (6&ndash;11)</span> <span className="stat-block"><b>Challenge Rating</b>:
2</span> <span className="stat-block"><b>Advancement</b>: 3&ndash;4 HD
(Medium); 5&ndash;6
HD (Large)</span> <span className="stat-block"><b>Level Adjustment</b>:
&mdash;</span>
    <p>These creatures feed primarily on carrion and offal, gathering
heaps of the stuff in which to build nests and lay eggs. A giant
bombardier beetle is about 6 feet long.</p>
    <p>Giant bombardier beetles normally attack only to defend
themselves, their nests, or their eggs.</p>
    <p><b>Acid Spray (Ex)</b>: When attacked or disturbed, the
creature can release a 10-foot cone of acidic vapor once per round.
Those within the cone must succeed on a DC 13 Fortitude save or take
1d4+2 points of acid damage. The save DC is Constitution-based.</p>
    <h3><a id="giant-fire-beetle"></a>GIANT FIRE BEETLE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <span className="size-block">Small Vermin</span> <span className="stat-block"><b>Hit Dice</b>: 1d8 (4 hp)</span> <span className="stat-block"><b>Initiative</b>: +0</span> <span className="stat-block"><b>Speed</b>: 30 ft. (6 squares)</span> <span className="stat-block"><b>Armor Class</b>: 16 (+1 size, +5
natural), touch 11, flat-footed 16</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +0/&ndash;4</span> <span className="stat-block"><b>Attack</b>:
Bite +1 melee (2d4)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +1 melee (2d4)</span> <span className="stat-block"><b>Space/Reach</b>:
5 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
&mdash;</span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., vermin traits</span> <span className="stat-block"><b>Saves</b>: Fort
+2, Ref +0, Will +0</span> <span className="stat-block"><b>Abilities</b>:
Str 10, Dex 11, Con
11, Int &mdash;, Wis 10, Cha 7</span> <span className="stat-block"><b>Skills</b>:
&mdash;</span> <span className="stat-block"><b>Feats</b>: &mdash;</span> <span className="stat-block"><b>Environment</b>: Warm plains</span> <span className="stat-block"><b>Organization</b>: Cluster (2&ndash;5) or
colony (6&ndash;11)</span> <span className="stat-block"><b>Challenge Rating</b>:
1/3</span> <span className="stat-block"><b>Advancement</b>: 2&ndash;3 HD
(Small)</span> <span className="stat-block"><b>Level Adjustment</b>:
&mdash;</span>
    <p>These luminous nocturnal insects are prized by miners and
adventurers. They have two glands, one above each eye, that produce a
red glow. The glands&rsquo; luminosity persists for 1d6 days after
removal
from the beetle, illuminating a roughly circular area with a 10-foot
radius. Giant fire beetles are about 2 feet long.</p>
    <h3><a id="giant-stag-beetle"></a>GIANT STAG BEETLE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <span className="size-block">Large Vermin</span> <span className="stat-block"><b>Hit Dice</b>: 7d8+21 (52 hp)</span> <span className="stat-block"><b>Initiative</b>: +0</span> <span className="stat-block"><b>Speed</b>: 20 ft. (4 squares)</span> <span className="stat-block"><b>Armor Class</b>: 19 (&ndash;1 size, +10
natural), touch 9, flat-footed 19</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +5/+15</span> <span className="stat-block"><b>Attack</b>:
Bite +10 melee (4d6+9)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +10 melee
(4d6+9)</span> <span className="stat-block"><b>Space/Reach</b>: 10 ft./5
ft.</span> <span className="stat-block"><b>Special Attacks</b>: Trample
2d8+3</span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., vermin traits</span> <span className="stat-block"><b>Saves</b>: Fort
+8, Ref +2, Will +2</span> <span className="stat-block"><b>Abilities</b>:
Str 23, Dex 10, Con
17, Int &mdash;, Wis 10, Cha 9</span> <span className="stat-block"><b>Skills</b>:
&mdash;</span> <span className="stat-block"><b>Feats</b>: &mdash;</span> <span className="stat-block"><b>Environment</b>: Temperate forests</span> <span className="stat-block"><b>Organization</b>: Cluster (2&ndash;5) or
mass (6&ndash;11)</span> <span className="stat-block"><b>Challenge Rating</b>:
4</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span>
    <span className="stat-block"><b>Advancement</b>: 8&ndash;10 HD
(Large);
11&ndash;21 HD (Huge)</span> <span className="stat-block"><b>Level
Adjustment</b>:
&mdash;</span>
    <p>These creatures are serious pests that greedily devour
cultivated crops. A single beetle can strip an entire farm in short
order. An adult giant stag beetle is about 10 feet long.</p>
    <p><b>Trample (Ex)</b>: Reflex half DC 19. The save DC is
Strength-based.</p>
    <h3><a id="giant-praying-mantis"></a>GIANT PRAYING MANTIS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <span className="size-block">Large Vermin</span> <span className="stat-block"><b>Hit Dice</b>: 4d8+8 (26 hp)</span> <span className="stat-block"><b>Initiative</b>: &ndash;1</span> <span className="stat-block"><b>Speed</b>: 20 ft. (4 squares), fly 40
ft. (poor)</span> <span className="stat-block"><b>Armor Class</b>: 14
(&ndash;1
size, &ndash;1 Dex,
+6 natural), touch 8, flat-footed 14</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +3/+11</span> <span className="stat-block"><b>Attack</b>:
Claws +6 melee (1d8+4)</span> <span className="stat-block"><b>Full Attack</b>:
Claws +6 melee
(1d8+4) and bite +1 melee (1d6+2)</span> <span className="stat-block"><b>Space/Reach</b>:
10 ft (4 squares)./5
ft.</span> <span className="stat-block"><b>Special Attacks</b>: Improved
grab</span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., vermin traits</span> <span className="stat-block"><b>Saves</b>: Fort
+6, Ref +0, Will +3</span> <span className="stat-block"><b>Abilities</b>:
Str 19, Dex 8, Con 15,
Int &mdash;, Wis 14, Cha 11</span> <span className="stat-block"><b>Skills</b>:
Hide &ndash;1*, Spot +6</span> <span className="stat-block"><b>Feats</b>:
&mdash;</span> <span className="stat-block"><b>Environment</b>: Temperate
forests</span> <span className="stat-block"><b>Organization</b>: Solitary</span>
    <span className="stat-block"><b>Challenge Rating</b>: 3</span> <span className="stat-block"><b>Advancement</b>: 5&ndash;8 HD (Large);
9&ndash;12
HD (Huge)</span> <span className="stat-block"><b>Level Adjustment</b>:
&mdash;</span>
    <p>This patient carnivore remains completely still as it waits
for prey to come near.</p>
    <p><b>Improved Grab (Ex)</b>: To use this ability, a giant
praying mantis must hit with its claws attack. If it wins the ensuing
grapple check, it establishes a hold and makes a bite attack as a
primary attack (at its full +6 attack bonus).</p>
    <p><b>Skills</b>: A giant praying mantis has a +4 racial bonus on
Hide and Spot checks. *Because of its camouflage, the Hide bonus
increases to +12 when a mantis is surrounded by foliage.</p>
    <h3><a id="giant-wasp"></a>GIANT WASP</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <span className="size-block">Large Vermin</span> <span className="stat-block"><b>Hit Dice</b>: 5d8+10 (32 hp)</span> <span className="stat-block"><b>Initiative</b>: +1</span> <span className="stat-block"><b>Speed</b>: 20 ft. (4 squares), fly 60
ft. (good)</span> <span className="stat-block"><b>Armor Class</b>: 14
(&ndash;1
size, +1 Dex,
+4 natural), touch 10, flat-footed 13</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +3/+11</span> <span className="stat-block"><b>Attack</b>:
Sting +6 melee (1d3+6
plus poison)</span> <span className="stat-block"><b>Full Attack</b>: Sting
+6 melee
(1d3+6 plus poison)</span> <span className="stat-block"><b>Space/Reach</b>:
10 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
Poison</span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., vermin traits</span> <span className="stat-block"><b>Saves</b>: Fort
+6, Ref +2, Will +2</span> <span className="stat-block"><b>Abilities</b>:
Str 18, Dex 12, Con
14, Int &mdash;, Wis 13, Cha 11</span> <span className="stat-block"><b>Skills</b>:
Spot +9, Survival +1*</span> <span className="stat-block"><b>Feats</b>:
&mdash;</span> <span className="stat-block"><b>Environment</b>: Temperate
forests</span> <span className="stat-block"><b>Organization</b>: Solitary,
swarm
(2&ndash;5), or nest (11&ndash;20)</span> <span className="stat-block"><b>Challenge
Rating</b>: 3</span> <span className="stat-block"><b>Advancement</b>:
6&ndash;8
HD (Large); 9&ndash;15
HD (Huge)</span> <span className="stat-block"><b>Level Adjustment</b>:
&mdash;</span>
    <p>Giant wasps attack when hungry or threatened, stinging their
prey to death. They take dead or incapacitated opponents back to their
lairs as food for their unhatched young.</p>
    <p><b>Poison (Ex)</b>: Injury, Fortitude DC 14, initial and
secondary damage 1d6 Dex. The save DC is Constitution-based.</p>
    <p><b>Skills</b>: Giant wasps have a +8 racial bonus on Spot
checks. *They also have a +4 racial bonus on Survival checks to orient
themselves.</p>
    <h3><a id="monstrous-centipede"></a>MONSTROUS CENTIPEDE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "16%"
          }}>
          </th>
          <th style={{
            "width": "27%"
          }}>Monstrous Centipede, Tiny
Tiny Vermin</th>
          <th style={{
            "width": "2%"
          }}>
          </th>
          <th style={{
            "width": "27%"
          }}>Monstrous Centipede, Small
Small Vermin</th>
          <th style={{
            "width": "2%"
          }}>
          </th>
          <th style={{
            "width": "27%"
          }}>Monstrous Centipede, Medium
Medium Vermin</th>
        </tr>
        <tr className="odd-row">
          <td><b>Hit Dice:</b></td>
          <td>1/4 d8 (1 hp)</td>
          <td>
          </td>
          <td>1/2 d8 (2 hp)</td>
          <td>
          </td>
          <td>1d8 (4 hp)</td>
        </tr>
        <tr>
          <td><b>Initiative:</b></td>
          <td>+2</td>
          <td>
          </td>
          <td>+2</td>
          <td>
          </td>
          <td>+2</td>
        </tr>
        <tr className="odd-row">
          <td><b>Speed:</b></td>
          <td>20 ft. (4 squares), climb 20 ft.</td>
          <td>
          </td>
          <td>30 ft. (6 squares), climb 30 ft.</td>
          <td>
          </td>
          <td>40 ft. (8 squares), climb 40 ft.</td>
        </tr>
        <tr>
          <td><b>Armor Class:</b></td>
          <td>14 (+2 size, +2 Dex), touch 14, flat-footed 12</td>
          <td>
          </td>
          <td>14 (+1 size, +2 Dex, +1 natural), touch 13, flat-footed
12</td>
          <td>
          </td>
          <td>14 (+2 Dex, +2 natural), touch 12, flat-footed 12</td>
        </tr>
        <tr className="odd-row">
          <td><b>Base Attack/Grapple:</b></td>
          <td>+0/&ndash;13</td>
          <td>
          </td>
          <td>+0/&ndash;7</td>
          <td>
          </td>
          <td>+0/&ndash;1</td>
        </tr>
        <tr>
          <td><b>Attack:</b></td>
          <td>Bite +4 melee (1d3&ndash;5 plus poison)</td>
          <td>
          </td>
          <td>Bite +3 melee (1d4&ndash;3 plus poison)</td>
          <td>
          </td>
          <td>Bite +2 melee (1d6&ndash;1 plus poison)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Full Attack:</b></td>
          <td>Bite +4 melee (1d3&ndash;5 plus poison)</td>
          <td>
          </td>
          <td>Bite +3 melee (1d4&ndash;3 plus poison)</td>
          <td>
          </td>
          <td>Bite +2 melee (1d6&ndash;1 plus poison)</td>
        </tr>
        <tr>
          <td><b>Space/Reach:</b></td>
          <td>2-1/2 ft./0 ft.</td>
          <td>
          </td>
          <td>5 ft./5 ft.</td>
          <td>
          </td>
          <td>5 ft./5 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><b>Special Attacks:</b></td>
          <td>Poison</td>
          <td>
          </td>
          <td>Poison</td>
          <td>
          </td>
          <td>Poison</td>
        </tr>
        <tr>
          <td><b>Special Qualities:</b></td>
          <td>Darkvision 60 ft., vermin traits</td>
          <td>
          </td>
          <td>Darkvision 60 ft., vermin traits</td>
          <td>
          </td>
          <td>Darkvision 60 ft., vermin traits</td>
        </tr>
        <tr className="odd-row">
          <td><b>Saves:</b></td>
          <td>Fort +2, Ref +2, Will +0</td>
          <td>
          </td>
          <td>Fort +2, Ref +2, Will +0</td>
          <td>
          </td>
          <td>Fort +2, Ref +2, Will +0</td>
        </tr>
        <tr>
          <td><b>Abilities:</b></td>
          <td>Str 1, Dex 15, Con 10, Int &mdash;, Wis 10, Cha 2</td>
          <td>
          </td>
          <td>Str 5, Dex 15, Con 10, Int &mdash;, Wis 10, Cha 2</td>
          <td>
          </td>
          <td>Str 9, Dex 15, Con 10, Int &mdash;, Wis 10, Cha 2</td>
        </tr>
        <tr className="odd-row">
          <td><b>Skills:</b></td>
          <td><a href="skillsAll.html#climb" style={{
              "color": "rgb(87, 158, 182)"
            }}>Climb</a> +10, Hide +18, Spot +4</td>
          <td>
          </td>
          <td>Climb +10, Hide +14, Spot +4</td>
          <td>
          </td>
          <td>Climb +10, Hide +10, Spot +4</td>
        </tr>
        <tr>
          <td><b>Feats:</b></td>
          <td>Weapon FinesseB</td>
          <td>
          </td>
          <td>Weapon FinesseB</td>
          <td>
          </td>
          <td>Weapon FinesseB</td>
        </tr>
        <tr className="odd-row">
          <td><b>Environment:</b></td>
          <td>Underground</td>
          <td>
          </td>
          <td>Underground</td>
          <td>
          </td>
          <td>Underground</td>
        </tr>
        <tr>
          <td><b>Organization:</b></td>
          <td>Colony (8&ndash;16)</td>
          <td>
          </td>
          <td>Colony (2&ndash;5) or swarm (6&ndash;11)</td>
          <td>
          </td>
          <td>Solitary or colony (2&ndash;5)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Challenge Rating:</b></td>
          <td>1/8</td>
          <td>
          </td>
          <td>1/4</td>
          <td>
          </td>
          <td>1/2</td>
        </tr>
        <tr>
          <td><b>Advancement:</b></td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td><b>Level Adjustment:</b></td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>&mdash;</td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "16%"
          }}>
          </th>
          <th style={{
            "width": "38%"
          }}>Monstrous Centipede, Large
Large Vermin</th>
          <th style={{
            "width": "4%"
          }}>
          </th>
          <th style={{
            "width": "42%"
          }}>Monstrous Centipede, Huge
Huge Vermin</th>
        </tr>
        <tr className="odd-row">
          <td><b>Hit Dice:</b></td>
          <td>3d8 (13 hp)</td>
          <td>
          </td>
          <td>6d8+6 (33 hp)</td>
        </tr>
        <tr>
          <td><b>Initiative:</b></td>
          <td>+2</td>
          <td>
          </td>
          <td>+2</td>
        </tr>
        <tr className="odd-row">
          <td><b>Speed:</b></td>
          <td>40 ft. (8 squares), climb 40 ft.</td>
          <td>
          </td>
          <td>40 ft. (8 squares), climb 40 ft.</td>
        </tr>
        <tr>
          <td><b>Armor Class:</b></td>
          <td>14 (&ndash;1 size, +2 Dex, +3 natural), touch 11,
flat-footed
12</td>
          <td>
          </td>
          <td>16 (&ndash;2 size, +2 Dex, +6 natural), touch 10,
flat-footed
14</td>
        </tr>
        <tr className="odd-row">
          <td><b>Base Attack/Grapple:</b></td>
          <td>+2/+7</td>
          <td>
          </td>
          <td>+4/+15</td>
        </tr>
        <tr>
          <td><b>Attack:</b></td>
          <td>Bite +3 melee (1d8+1 plus poison)</td>
          <td>
          </td>
          <td>Bite +5 melee (2d6+4 plus poison)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Full Attack:</b></td>
          <td>Bite +3 melee (1d8+1 plus poison)</td>
          <td>
          </td>
          <td>Bite +5 melee (2d6+4 plus poison)</td>
        </tr>
        <tr>
          <td><b>Space/Reach:</b></td>
          <td>10 ft./5 ft.</td>
          <td>
          </td>
          <td>15 ft./10 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><b>Special Attacks:</b></td>
          <td>Poison</td>
          <td>
          </td>
          <td>Poison</td>
        </tr>
        <tr>
          <td><b>Special Qualities:</b></td>
          <td>Darkvision 60 ft., vermin traits</td>
          <td>
          </td>
          <td>Darkvision 60 ft., vermin traits</td>
        </tr>
        <tr className="odd-row">
          <td><b>Saves:</b></td>
          <td>Fort +3, Ref +3, Will +1</td>
          <td>
          </td>
          <td>Fort +6, Ref +4, Will +2</td>
        </tr>
        <tr>
          <td><b>Abilities:</b></td>
          <td>Str 13, Dex 15, Con 10, Int &mdash;, Wis 10, Cha 2</td>
          <td>
          </td>
          <td>Str 17, Dex 15, Con 12, Int &mdash;, Wis 10, Cha 2</td>
        </tr>
        <tr className="odd-row">
          <td><b>Skills:</b></td>
          <td>Climb +10, Hide +6, Spot +4</td>
          <td>
          </td>
          <td>Climb +11, Hide +2, Spot +4</td>
        </tr>
        <tr>
          <td><b>Feats:</b></td>
          <td>Weapon FinesseB</td>
          <td>
          </td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td><b>Environment:</b></td>
          <td>Underground</td>
          <td>
          </td>
          <td>Underground</td>
        </tr>
        <tr>
          <td><b>Organization:</b></td>
          <td>Solitary or colony (2&ndash;5)</td>
          <td>
          </td>
          <td>Solitary or colony (2&ndash;5)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Challenge Rating:</b></td>
          <td>1</td>
          <td>
          </td>
          <td>2</td>
        </tr>
        <tr>
          <td><b>Advancement:</b></td>
          <td>4&ndash;5 HD (Large)</td>
          <td>
          </td>
          <td>7&ndash;11 HD (Huge)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Level Adjustment:</b></td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>&mdash;</td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "16%"
          }}>
          </th>
          <th style={{
            "width": "38%"
          }}>Monstrous Centipede, Gargantuan
Gargantuan Vermin</th>
          <th style={{
            "width": "4%"
          }}>
          </th>
          <th style={{
            "width": "42%"
          }}>Monstrous Centipede, Colossal
Colossal Vermin</th>
        </tr>
        <tr className="odd-row">
          <td><b>Hit Dice:</b></td>
          <td>12d8+12 (66 hp)</td>
          <td>
          </td>
          <td>24d8+24 (132 hp)</td>
        </tr>
        <tr>
          <td><b>Initiative:</b></td>
          <td>+2</td>
          <td>
          </td>
          <td>+1</td>
        </tr>
        <tr className="odd-row">
          <td><b>Speed:</b></td>
          <td>40 ft. (8 squares), climb 40 ft.</td>
          <td>
          </td>
          <td>40 ft. (8 squares), climb 40 ft.</td>
        </tr>
        <tr>
          <td><b>Armor Class:</b></td>
          <td>18 (&ndash;4 size, +2 Dex, +10 natural), touch 8,
flat-footed
16</td>
          <td>
          </td>
          <td>20 (&ndash;8 size, +2 Dex, +16 natural), touch 4,
flat-footed
18</td>
        </tr>
        <tr className="odd-row">
          <td><b>Base Attack/Grapple:</b></td>
          <td>+9/+27</td>
          <td>
          </td>
          <td>+18/+42</td>
        </tr>
        <tr>
          <td><b>Attack:</b></td>
          <td>Bite +11 melee (2d8+9 plus poison)</td>
          <td>
          </td>
          <td>Bite +18 melee (4d6+12 plus poison)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Full Attack:</b></td>
          <td>Bite +11 melee (2d8+9 plus poison)</td>
          <td>
          </td>
          <td>Bite +18 melee (4d6+12 plus poison)</td>
        </tr>
        <tr>
          <td><b>Space/Reach:</b></td>
          <td>20 ft./15 ft.</td>
          <td>
          </td>
          <td>30 ft./20 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><b>Special Attacks:</b></td>
          <td>Poison</td>
          <td>
          </td>
          <td>Poison</td>
        </tr>
        <tr>
          <td><b>Special Qualities:</b></td>
          <td>Darkvision 60 ft., vermin traits</td>
          <td>
          </td>
          <td>Darkvision 60 ft., vermin traits</td>
        </tr>
        <tr className="odd-row">
          <td><b>Saves:</b></td>
          <td>Fort +9, Ref +6, Will +4</td>
          <td>
          </td>
          <td>Fort +15, Ref +9, Will +8</td>
        </tr>
        <tr>
          <td><b>Abilities:</b></td>
          <td>Str 23, Dex 15, Con 12, Int &mdash;, Wis 10, Cha 2</td>
          <td>
          </td>
          <td>Str 27, Dex 13, Con 12, Int &mdash;, Wis 10, Cha 2</td>
        </tr>
        <tr className="odd-row">
          <td><b>Skills:</b></td>
          <td>Climb +14, Hide &ndash;2, Spot +4</td>
          <td>
          </td>
          <td>Climb +16, Hide &ndash;7, Spot +4</td>
        </tr>
        <tr>
          <td><b>Feats:</b></td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td><b>Environment:</b></td>
          <td>Underground</td>
          <td>
          </td>
          <td>Underground</td>
        </tr>
        <tr>
          <td><b>Organization:</b></td>
          <td>Solitary</td>
          <td>
          </td>
          <td>Solitary</td>
        </tr>
        <tr className="odd-row">
          <td><b>Challenge Rating:</b></td>
          <td>6</td>
          <td>
          </td>
          <td>9</td>
        </tr>
        <tr>
          <td><b>Advancement:</b></td>
          <td>17&ndash;23 HD (Gargantuan)</td>
          <td>
          </td>
          <td>25&ndash;48 HD (Colossal)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Level Adjustment:</b></td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>&mdash;</td>
        </tr>
      </tbody>
    </table>
    <p>Monstrous centipedes tend to attack anything that resembles
food, biting with their jaws and injecting their poison.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Size</th>
          <th style={{
            "width": "75px"
          }}>Fort DC</th>
          <th style={{
            "width": "75px"
          }}>Damage</th>
        </tr>
        <tr className="odd-row">
          <td>Tiny</td>
          <td>10</td>
          <td>1 Dex</td>
        </tr>
        <tr>
          <td>Small</td>
          <td>10</td>
          <td>1d2 Dex</td>
        </tr>
        <tr className="odd-row">
          <td>Medium</td>
          <td>10</td>
          <td>1d3 Dex</td>
        </tr>
        <tr>
          <td>Large</td>
          <td>11</td>
          <td>1d4 Dex</td>
        </tr>
        <tr className="odd-row">
          <td>Huge</td>
          <td>14</td>
          <td>1d6 Dex</td>
        </tr>
        <tr>
          <td>Gargantuan</td>
          <td>17</td>
          <td>1d8 Dex</td>
        </tr>
        <tr className="odd-row">
          <td>Colossal</td>
          <td>23</td>
          <td>2d6 Dex</td>
        </tr>
      </tbody>
    </table>
    <p><b>Poison (Ex)</b>: A monstrous centipede has a poisonous
bite. The details vary by the centipede&rsquo;s size, as shown on the
table
above. The save DCs are Constitution-based. The indicated damage is
both initial and secondary damage. </p>
    <p><b>Skills</b>: Monstrous centipedes have a +4 racial bonus on
Spot checks, and a +8 racial bonus on Climb and Hide checks. They can
use either their Strength or Dexterity modifier for Climb checks,
whichever is higher. Monstrous scorpions can take 10 on Climb checks,
even if threatened or distracted.</p>
    <h3><a id="monstrous-scorpion"></a>MONSTROUS SCORPION</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "16%"
          }}>
          </th>
          <th style={{
            "width": "27%"
          }}>Monstrous Scorpion, Tiny
Tiny Vermin</th>
          <th style={{
            "width": "2%"
          }}>
          </th>
          <th style={{
            "width": "27%"
          }}>Monstrous Scorpion, Small
Small Vermin</th>
          <th style={{
            "width": "2%"
          }}>
          </th>
          <th style={{
            "width": "27%"
          }}>Monstrous Scorpion, Medium
Medium Vermin</th>
        </tr>
        <tr className="odd-row">
          <td><b>Hit Dice:</b></td>
          <td>1/2 d8+2 (4 hp)</td>
          <td>
          </td>
          <td>1d8+2 (6 hp)</td>
          <td>
          </td>
          <td>2d8+4 (13 hp)</td>
        </tr>
        <tr>
          <td><b>Initiative:</b></td>
          <td>+0</td>
          <td>
          </td>
          <td>+0</td>
          <td>
          </td>
          <td>+0</td>
        </tr>
        <tr className="odd-row">
          <td><b>Speed:</b></td>
          <td>20 ft. (4 squares)</td>
          <td>
          </td>
          <td>30 ft. (6 squares)</td>
          <td>
          </td>
          <td>40 ft. (8 squares)</td>
        </tr>
        <tr>
          <td><b>Armor Class:</b></td>
          <td>14 (+2 size, +2 natural), touch 12, flat-footed 14</td>
          <td>
          </td>
          <td>14 (+1 size, +3 natural), touch 11, flat-footed 14</td>
          <td>
          </td>
          <td>14 (+4 natural), touch 10, flat-footed 14</td>
        </tr>
        <tr className="odd-row">
          <td><b>Base Attack/Grapple:</b></td>
          <td>+0/&ndash;8</td>
          <td>
          </td>
          <td>+0/&ndash;4</td>
          <td>
          </td>
          <td>+1/+2</td>
        </tr>
        <tr>
          <td><b>Attack:</b></td>
          <td>Claw +2 melee (1d2&ndash;4)</td>
          <td>
          </td>
          <td>Claw +1 melee (1d3&ndash;1)</td>
          <td>
          </td>
          <td>Claw +2 melee (1d4+1)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Full Attack:</b></td>
          <td>2 claws +2 melee (1d2&ndash;4) and sting &ndash;3 melee
(1d2&ndash;4 plus
poison)</td>
          <td>
          </td>
          <td>2 claws +1 melee (1d3&ndash;1) and sting &ndash;4 melee
(1d3&ndash;1 plus
poison)</td>
          <td>
          </td>
          <td>2 claws +2 melee (1d4+1) and sting &ndash;3 melee (1d4
plus
poison)</td>
        </tr>
        <tr>
          <td><b>Space/Reach:</b></td>
          <td>2-1/2 ft./0 ft.</td>
          <td>
          </td>
          <td>5 ft./5 ft.</td>
          <td>
          </td>
          <td>5 ft./5 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><b>Special Attacks:</b></td>
          <td>Constrict 1d2&ndash;4, improved grab, poison</td>
          <td>
          </td>
          <td>Constrict 1d3&ndash;1, improved grab, poison</td>
          <td>
          </td>
          <td>Constrict 1d4+1, improved grab, poison</td>
        </tr>
        <tr>
          <td><b>Special Qualities:</b></td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
          <td>
          </td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
          <td>
          </td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
        </tr>
        <tr className="odd-row">
          <td><b>Saves:</b></td>
          <td>Fort +4, Ref +0, Will +0</td>
          <td>
          </td>
          <td>Fort +4, Ref +0, Will +0</td>
          <td>
          </td>
          <td>Fort +5, Ref +0, Will +0</td>
        </tr>
        <tr>
          <td><b>Abilities:</b></td>
          <td>Str 3, Dex 10, Con 14, Int &mdash;, Wis 10, Cha 2</td>
          <td>
          </td>
          <td>Str 9, Dex 10, Con 14, Int &mdash;, Wis 10, Cha 2</td>
          <td>
          </td>
          <td>Str 13, Dex 10, Con 14, Int &mdash;, Wis 10, Cha 2</td>
        </tr>
        <tr className="odd-row">
          <td><b>Skills:</b></td>
          <td><a href="skillsAll.html#climb" style={{
              "color": "rgb(87, 158, 182)"
            }}>Climb</a> +0, Hide +12, Spot +4</td>
          <td>
          </td>
          <td>Climb +3, Hide +8, Spot +4</td>
          <td>
          </td>
          <td>Climb +5, Hide +4, Spot +4</td>
        </tr>
        <tr>
          <td><b>Feats:</b></td>
          <td>Weapon FinesseB</td>
          <td>
          </td>
          <td>Weapon FinesseB</td>
          <td>
          </td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td><b>Environment:</b></td>
          <td>Warm deserts</td>
          <td>
          </td>
          <td>Warm deserts</td>
          <td>
          </td>
          <td>Warm deserts</td>
        </tr>
        <tr>
          <td><b>Organization:</b></td>
          <td>Colony (8&ndash;16)</td>
          <td>
          </td>
          <td>Colony (2&ndash;5) or swarm (6&ndash;11)</td>
          <td>
          </td>
          <td>Solitary or colony (2&ndash;5)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Challenge Rating:</b></td>
          <td>1/4</td>
          <td>
          </td>
          <td>1/2</td>
          <td>
          </td>
          <td>1</td>
        </tr>
        <tr>
          <td><b>Alignment:</b></td>
          <td>Always neutral</td>
          <td>
          </td>
          <td>Always neutral</td>
          <td>
          </td>
          <td>Always neutral</td>
        </tr>
        <tr className="odd-row">
          <td><b>Advancement:</b></td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>3&ndash;4 HD (Medium)</td>
        </tr>
        <tr>
          <td className="last-row"><b>Level Adjustment:</b></td>
          <td className="last-row">&mdash;</td>
          <td className="last-row">&nbsp;</td>
          <td className="last-row">&mdash;</td>
          <td className="last-row">&nbsp;</td>
          <td className="last-row">&mdash;</td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "16%"
          }}>
          </th>
          <th style={{
            "width": "38%"
          }}>Monstrous Scorpion, Large
Large Vermin</th>
          <th style={{
            "width": "4%"
          }}>
          </th>
          <th style={{
            "width": "42%"
          }}>Monstrous Scorpion, Huge
Huge Vermin</th>
        </tr>
        <tr className="odd-row">
          <td><b>Hit Dice:</b></td>
          <td>5d8+10 (32 hp)</td>
          <td>
          </td>
          <td>10d8+30 (75 hp)</td>
        </tr>
        <tr>
          <td><b>Initiative:</b></td>
          <td>+0</td>
          <td>
          </td>
          <td>+0</td>
        </tr>
        <tr className="odd-row">
          <td><b>Speed:</b></td>
          <td>50 ft. (10 squares)</td>
          <td>
          </td>
          <td>50 ft. (10 squares)</td>
        </tr>
        <tr>
          <td><b>Armor Class:</b></td>
          <td>16 (&ndash;1 size, +7 natural), touch 9, flat-footed 16</td>
          <td>
          </td>
          <td>20 (&ndash;2 size, +12 natural), touch 8, flat-footed 20</td>
        </tr>
        <tr className="odd-row">
          <td><b>Base Attack/Grapple:</b></td>
          <td>+3/+11</td>
          <td>
          </td>
          <td>+7/+21</td>
        </tr>
        <tr>
          <td><b>Attack:</b></td>
          <td>Claw +6 melee (1d6+4)</td>
          <td>
          </td>
          <td>Claw +11 melee (1d8+6)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Full Attack:</b></td>
          <td>2 claws +6 melee (1d6+4) and sting +1 melee (1d6+2 plus
poison)</td>
          <td>
          </td>
          <td>2 claws +11 melee (1d8+6) and sting +6 melee (2d4+3
plus poison)</td>
        </tr>
        <tr>
          <td><b>Space/Reach:</b></td>
          <td>10 ft./5 ft.</td>
          <td>
          </td>
          <td>15 ft./10 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><b>Special Attacks:</b></td>
          <td>Constrict 1d6+4, improved grab, poison</td>
          <td>
          </td>
          <td>Constrict 1d8+6, improved grab, poison</td>
        </tr>
        <tr>
          <td><b>Special Qualities:</b></td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
          <td>
          </td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
        </tr>
        <tr className="odd-row">
          <td><b>Saves:</b></td>
          <td>Fort +6, Ref +1, Will +1</td>
          <td>
          </td>
          <td>Fort +10, Ref +3, Will +3</td>
        </tr>
        <tr>
          <td><b>Abilities:</b></td>
          <td>Str 19, Dex 10, Con 14, Int &mdash;, Wis 10, Cha 2</td>
          <td>
          </td>
          <td>Str 23, Dex 10, Con 16, Int &mdash;, Wis 10, Cha 2</td>
        </tr>
        <tr className="odd-row">
          <td><b>Skills:</b></td>
          <td>Climb +8, Hide +0, Spot +4</td>
          <td>
          </td>
          <td>Climb +10, Hide &ndash;4, Spot +4</td>
        </tr>
        <tr>
          <td><b>Feats:</b></td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td><b>Environment:</b></td>
          <td>Warm deserts</td>
          <td>
          </td>
          <td>Warm deserts</td>
        </tr>
        <tr>
          <td><b>Organization:</b></td>
          <td>Solitary or colony (2&ndash;5)</td>
          <td>
          </td>
          <td>Solitary or colony (2&ndash;5)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Challenge Rating:</b></td>
          <td>3</td>
          <td>
          </td>
          <td>7</td>
        </tr>
        <tr>
          <td><b>Treasure:</b></td>
          <td>1/10 coins; 50% goods; 50% items</td>
          <td>
          </td>
          <td>1/10 coins; 50% goods; 50% items</td>
        </tr>
        <tr className="odd-row">
          <td><b>Advancement:</b></td>
          <td>6&ndash;9 HD (Large)</td>
          <td>
          </td>
          <td>11&ndash;19 HD (Huge)</td>
        </tr>
        <tr>
          <td className="last-row"><b>Level Adjustment:</b></td>
          <td className="last-row">&mdash;</td>
          <td className="last-row">&nbsp;</td>
          <td className="last-row">&mdash;</td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "16%"
          }}>
          </th>
          <th style={{
            "width": "38%"
          }}>Monstrous Scorpion, Gargantuan
Gargantuan Vermin</th>
          <th style={{
            "width": "4%"
          }}>
          </th>
          <th style={{
            "width": "42%"
          }}>Monstrous Scorpion, Colossal
Colossal Vermin</th>
        </tr>
        <tr className="odd-row">
          <td><b>Hit Dice:</b></td>
          <td>20d8+60 (150 hp)</td>
          <td>
          </td>
          <td>40d8+120 (300 hp)</td>
        </tr>
        <tr>
          <td><b>Initiative:</b></td>
          <td>+0</td>
          <td>
          </td>
          <td>&ndash;1</td>
        </tr>
        <tr className="odd-row">
          <td><b>Speed:</b></td>
          <td>50 ft. (10 squares)</td>
          <td>
          </td>
          <td>50 ft. (10 squares)</td>
        </tr>
        <tr>
          <td><b>Armor Class:</b></td>
          <td>24 (&ndash;4 size, +18 natural), touch 6, flat-footed 24</td>
          <td>
          </td>
          <td>26 (&ndash;8 size, &ndash;1 Dex, +25 natural), touch 1,
flat-footed
26</td>
        </tr>
        <tr className="odd-row">
          <td><b>Base Attack/Grapple:</b></td>
          <td>+15/+37</td>
          <td>
          </td>
          <td>+30/+58</td>
        </tr>
        <tr>
          <td><b>Attack:</b></td>
          <td>Claw +21 melee (2d6+10)</td>
          <td>
          </td>
          <td>Claw +34 melee (2d8+12)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Full Attack:</b></td>
          <td>2 claws +21 melee (2d6+10) and sting +16 melee (2d6+5
plus poison)</td>
          <td>
          </td>
          <td>2 claws +34 melee (2d8+12) and sting +29 melee (2d8+6
plus poison)</td>
        </tr>
        <tr>
          <td><b>Space/Reach:</b></td>
          <td>20 ft./15 ft.</td>
          <td>
          </td>
          <td>40 ft./30 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><b>Special Attacks:</b></td>
          <td>Constrict 2d6+10, improved grab, poison</td>
          <td>
          </td>
          <td>Constrict 2d8+12, improved grab, poison</td>
        </tr>
        <tr>
          <td><b>Special Qualities:</b></td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
          <td>
          </td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
        </tr>
        <tr className="odd-row">
          <td><b>Saves:</b></td>
          <td>Fort +15, Ref +6, Will +6</td>
          <td>
          </td>
          <td>Fort +25, Ref +12, Will +13</td>
        </tr>
        <tr>
          <td><b>Abilities:</b></td>
          <td>Str 31, Dex 10, Con 16, Int &mdash;, Wis 10, Cha 2</td>
          <td>
          </td>
          <td>Str 35, Dex 8, Con 16, Int &mdash;, Wis 10, Cha 2</td>
        </tr>
        <tr className="odd-row">
          <td><b>Skills:</b></td>
          <td>Climb +14, Hide &ndash;8, Spot +4</td>
          <td>
          </td>
          <td>Climb +16, Hide &ndash;12, Spot +4</td>
        </tr>
        <tr>
          <td><b>Feats:</b></td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td><b>Environment:</b></td>
          <td>Warm deserts</td>
          <td>
          </td>
          <td>Warm deserts</td>
        </tr>
        <tr>
          <td><b>Organization:</b></td>
          <td>Solitary</td>
          <td>
          </td>
          <td>Solitary</td>
        </tr>
        <tr className="odd-row">
          <td><b>Challenge Rating:</b></td>
          <td>10</td>
          <td>
          </td>
          <td>12</td>
        </tr>
        <tr>
          <td><b>Treasure:</b></td>
          <td>1/10 coins; 50% goods; 50% items</td>
          <td>
          </td>
          <td>1/10 coins; 50% goods; 50% items</td>
        </tr>
        <tr className="odd-row">
          <td><b>Advancement:</b></td>
          <td>21&ndash;39 HD (Gargantuan)</td>
          <td>
          </td>
          <td>41&ndash;60 HD (Colossal)</td>
        </tr>
        <tr>
          <td className="last-row"><b>Level Adjustment:</b></td>
          <td className="last-row">&mdash;</td>
          <td className="last-row">&nbsp;</td>
          <td className="last-row">&mdash;</td>
        </tr>
      </tbody>
    </table>
    <p>Monstrous scorpions are likely to attack any creature that
approaches, and they usually charge when attacking prey.</p>
    <p><b>Constrict (Ex)</b>: A monstrous scorpion deals automatic
claw damage on a successful grapple check.</p>
    <p><b>Improved Grab (Ex)</b>: To use this ability, a monstrous
scorpion must hit with a claw attack. A monstrous scorpion can use
either its Strength modifier or Dexterity modifier for grapple checks,
whichever is better.</p>
    <p><b>Poison (Ex)</b>: A monstrous scorpion has a poisonous
sting. The details vary by the scorpion&rsquo;s size, as follows. The
save
DCs are Constitution- based. The indicated damage is initial and
secondary damage.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Size</th>
          <th style={{
            "width": "75px"
          }}>Fort DC</th>
          <th style={{
            "width": "75px"
          }}>Damage</th>
        </tr>
        <tr className="odd-row">
          <td>Tiny</td>
          <td>12</td>
          <td>1 Con</td>
        </tr>
        <tr>
          <td>Small</td>
          <td>12</td>
          <td>1d2 Con</td>
        </tr>
        <tr className="odd-row">
          <td>Medium</td>
          <td>13</td>
          <td>1d3 Con</td>
        </tr>
        <tr>
          <td>Large</td>
          <td>14</td>
          <td>1d4 Con</td>
        </tr>
        <tr className="odd-row">
          <td>Huge</td>
          <td>18</td>
          <td>1d6 Con</td>
        </tr>
        <tr>
          <td>Gargantuan</td>
          <td>23</td>
          <td>1d8 Con</td>
        </tr>
        <tr className="odd-row">
          <td>Colossal</td>
          <td>33</td>
          <td>1d10 Con</td>
        </tr>
      </tbody>
    </table>
    <p><b>Skills</b>: A monstrous scorpion has a +4 racial bonus on
Climb, Hide, and Spot checks.</p>
    <h3><a id="monstrous-spider"></a>MONSTROUS SPIDER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "16%"
          }}>
          </th>
          <th style={{
            "width": "27%"
          }}>Monstrous Spider, Tiny
Tiny Vermin</th>
          <th style={{
            "width": "2%"
          }}>
          </th>
          <th style={{
            "width": "27%"
          }}>Monstrous Spider, Small
Small Vermin</th>
          <th style={{
            "width": "2%"
          }}>
          </th>
          <th style={{
            "width": "27%"
          }}>Monstrous Spider, Medium
Medium Vermin</th>
        </tr>
        <tr className="odd-row">
          <td><b>Hit Dice:</b></td>
          <td>1/2 d8 (2 hp)</td>
          <td>
          </td>
          <td>1d8 (4 hp)</td>
          <td>
          </td>
          <td>2d8+2 (11 hp)</td>
        </tr>
        <tr>
          <td><b>Initiative:</b></td>
          <td>+3</td>
          <td>
          </td>
          <td>+3</td>
          <td>
          </td>
          <td>+3</td>
        </tr>
        <tr className="odd-row">
          <td><b>Speed:</b></td>
          <td>20 ft. (4 squares), climb 10 ft.</td>
          <td>
          </td>
          <td>30 ft. (6 squares), climb 20 ft.</td>
          <td>
          </td>
          <td>30 ft. (6 squares), climb 20 ft.</td>
        </tr>
        <tr>
          <td><b>Armor Class:</b></td>
          <td>15 (+2 size, +3 Dex), touch 15, flat-footed 12</td>
          <td>
          </td>
          <td>14 (+1 size, +3 Dex), touch 14, flat-footed 11</td>
          <td>
          </td>
          <td>14 (+3 Dex, +1 natural), touch 13, flat-footed 11</td>
        </tr>
        <tr className="odd-row">
          <td><b>Base Attack/Grapple:</b></td>
          <td>+0/&ndash;12</td>
          <td>
          </td>
          <td>+0/&ndash;6</td>
          <td>
          </td>
          <td>+1/+1</td>
        </tr>
        <tr>
          <td><b>Attack:</b></td>
          <td>Bite +5 melee (1d3&ndash;4 plus poison)</td>
          <td>
          </td>
          <td>Bite +4 melee (1d4&ndash;2 plus poison)</td>
          <td>
          </td>
          <td>Bite +4 melee (1d6 plus poison)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Full Attack:</b></td>
          <td>Bite +5 melee (1d3&ndash;4 plus poison)</td>
          <td>
          </td>
          <td>Bite +4 melee (1d4&ndash;2 plus poison)</td>
          <td>
          </td>
          <td>Bite +4 melee (1d6 plus poison)</td>
        </tr>
        <tr>
          <td><b>Space/Reach:</b></td>
          <td>2-1/2 ft./0 ft.</td>
          <td>
          </td>
          <td>5 ft./5 ft.</td>
          <td>
          </td>
          <td>5 ft./5 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><b>Special Attacks:</b></td>
          <td>Poison, web</td>
          <td>
          </td>
          <td>Poison, web</td>
          <td>
          </td>
          <td>Poison, web</td>
        </tr>
        <tr>
          <td><b>Special Qualities:</b></td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
          <td>
          </td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
          <td>
          </td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
        </tr>
        <tr className="odd-row">
          <td><b>Saves:</b></td>
          <td>Fort +2, Ref +3, Will +0</td>
          <td>
          </td>
          <td>Fort +2, Ref +3, Will +0</td>
          <td>
          </td>
          <td>Fort +4, Ref +3, Will +0</td>
        </tr>
        <tr>
          <td><b>Abilities:</b></td>
          <td>Str 3, Dex 17, Con 10, Int &mdash;, Wis 10, Cha 2</td>
          <td>
          </td>
          <td>Str 7, Dex 17, Con 10, Int &mdash;, Wis 10, Cha 2</td>
          <td>
          </td>
          <td>Str 11, Dex 17, Con 12, Int &mdash;, Wis 10, Cha 2</td>
        </tr>
        <tr className="odd-row">
          <td><b>Skills:</b></td>
          <td><a href="skillsAll.html#climb" style={{
              "color": "rgb(87, 158, 182)"
            }}>Climb</a> +11, Hide +15*, Jump
&ndash;4*,
Spot +4*</td>
          <td>
          </td>
          <td>Climb +11, Hide +11*, Jump &ndash;2*, Spot +4*</td>
          <td>
          </td>
          <td>Climb +11, Hide +7*, Jump +0*, Spot +4*</td>
        </tr>
        <tr>
          <td><b>Feats:</b></td>
          <td>Weapon FinesseB</td>
          <td>
          </td>
          <td>Weapon FinesseB</td>
          <td>
          </td>
          <td>Weapon FinesseB</td>
        </tr>
        <tr className="odd-row">
          <td><b>Environment:</b></td>
          <td>Temperate forests</td>
          <td>
          </td>
          <td>Temperate forests</td>
          <td>
          </td>
          <td>Temperate forests</td>
        </tr>
        <tr>
          <td><b>Organization:</b></td>
          <td>Colony (8&ndash;16)</td>
          <td>
          </td>
          <td>Colony (2&ndash;5) or swarm (6&ndash;11)</td>
          <td>
          </td>
          <td>Solitary or colony (2&ndash;5)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Challenge Rating:</b></td>
          <td>1/4</td>
          <td>
          </td>
          <td>1/2</td>
          <td>
          </td>
          <td>1</td>
        </tr>
        <tr>
          <td><b>Treasure:</b></td>
          <td>None</td>
          <td>
          </td>
          <td>None</td>
          <td>
          </td>
          <td>1/10 coins; 50% goods; 50% items</td>
        </tr>
        <tr className="odd-row">
          <td><b>Advancement:</b></td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>3 HD (Medium)</td>
        </tr>
        <tr>
          <td className="last-row"><b>Level Adjustment:</b></td>
          <td className="last-row">&mdash;</td>
          <td className="last-row">&nbsp;</td>
          <td className="last-row">&mdash;</td>
          <td className="last-row">&nbsp;</td>
          <td className="last-row">&mdash;</td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "16%"
          }}>
          </th>
          <th style={{
            "width": "38%"
          }}>Monstrous Spider, Large
Large Vermin</th>
          <th style={{
            "width": "4%"
          }}>
          </th>
          <th style={{
            "width": "42%"
          }}>Monstrous Spider, Huge
Huge Vermim</th>
        </tr>
        <tr className="odd-row">
          <td><b>Hit Dice:</b></td>
          <td>4d8+4 (22 hp)</td>
          <td>
          </td>
          <td>8d8+16 (52 hp)</td>
        </tr>
        <tr>
          <td><b>Initiative:</b></td>
          <td>+3</td>
          <td>
          </td>
          <td>+3</td>
        </tr>
        <tr className="odd-row">
          <td><b>Speed:</b></td>
          <td>30 ft. (6 squares), climb 20 ft.</td>
          <td>
          </td>
          <td>30 ft. (6 squares), climb 20 ft.</td>
        </tr>
        <tr>
          <td><b>Armor Class:</b></td>
          <td>14 (&ndash;1 size, +3 Dex, +2 natural), touch 12,
flat-footed
11</td>
          <td>
          </td>
          <td>16 (&ndash;2 size, +3 Dex, +5 natural), touch 11,
flat-footed
13</td>
        </tr>
        <tr className="odd-row">
          <td><b>Base Attack/Grapple:</b></td>
          <td>+3/+9</td>
          <td>
          </td>
          <td>+6/+18</td>
        </tr>
        <tr>
          <td><b>Attack:</b></td>
          <td>Bite +4 melee (1d8+3 plus poison)</td>
          <td>
          </td>
          <td>Bite +9 melee (2d6+6 plus poison)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Full Attack:</b></td>
          <td>Bite +4 melee (1d8+3 plus poison)</td>
          <td>
          </td>
          <td>Bite +9 melee (2d6+6 plus poison)</td>
        </tr>
        <tr>
          <td><b>Space/Reach:</b></td>
          <td>10 ft./5 ft.</td>
          <td>
          </td>
          <td>15 ft./10 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><b>Special Attacks:</b></td>
          <td>Poison, web</td>
          <td>
          </td>
          <td>Poison, web</td>
        </tr>
        <tr>
          <td><b>Special Qualities:</b></td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
          <td>
          </td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
        </tr>
        <tr className="odd-row">
          <td><b>Saves:</b></td>
          <td>Fort +5, Ref +4, Will +1</td>
          <td>
          </td>
          <td>Fort +8, Ref +5, Will +2</td>
        </tr>
        <tr>
          <td><b>Abilities:</b></td>
          <td>Str 15, Dex 17, Con 12, Int &mdash;, Wis 10, Cha 2</td>
          <td>
          </td>
          <td>Str 19, Dex 17, Con 14, Int &mdash;, Wis 10, Cha 2</td>
        </tr>
        <tr className="odd-row">
          <td><b>Skills:</b></td>
          <td>Climb +11, Hide +3*, Jump +2*, Spot +4*</td>
          <td>
          </td>
          <td>Climb +12, Hide &ndash;1*, Jump +4*, Spot +4*</td>
        </tr>
        <tr>
          <td><b>Feats:</b></td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td><b>Environment:</b></td>
          <td>Temperate forests</td>
          <td>
          </td>
          <td>Temperate forests</td>
        </tr>
        <tr>
          <td><b>Organization:</b></td>
          <td>Solitary or colony (2&ndash;5)</td>
          <td>
          </td>
          <td>Solitary or colony (2&ndash;5)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Challenge Rating:</b></td>
          <td>2</td>
          <td>
          </td>
          <td>5</td>
        </tr>
        <tr>
          <td><b>Treasure:</b></td>
          <td>1/10 coins; 50% goods; 50% items</td>
          <td>
          </td>
          <td>1/10 coins; 50% goods; 50% items</td>
        </tr>
        <tr className="odd-row">
          <td><b>Advancement:</b></td>
          <td>5&ndash;7 HD (Large)</td>
          <td>
          </td>
          <td>9&ndash;15 HD (Huge)</td>
        </tr>
        <tr>
          <td className="last-row"><b>Level Adjustment:</b></td>
          <td className="last-row">&mdash;</td>
          <td className="last-row">&nbsp;</td>
          <td className="last-row">&mdash;</td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "16%"
          }}>
          </th>
          <th style={{
            "width": "38%"
          }}>Monstrous Spider, Gargantuan
Gargantuan Vermin</th>
          <th style={{
            "width": "4%"
          }}>
          </th>
          <th style={{
            "width": "42%"
          }}>Monstrous Spider, Colossal
Colossal Vermin</th>
        </tr>
        <tr className="odd-row">
          <td><b>Hit Dice:</b></td>
          <td>16d8+32 (104 hp)</td>
          <td>
          </td>
          <td>32d8+64 (208 hp)</td>
        </tr>
        <tr>
          <td><b>Initiative:</b></td>
          <td>+3</td>
          <td>
          </td>
          <td>+2</td>
        </tr>
        <tr className="odd-row">
          <td><b>Speed:</b></td>
          <td>30 ft. (6 squares), climb 20 ft.</td>
          <td>
          </td>
          <td>30 ft. (6 squares), climb 20 ft.</td>
        </tr>
        <tr>
          <td><b>Armor Class:</b></td>
          <td>19 (&ndash;4 size, +3 Dex, +10 natural), touch 9,
flat-footed
16</td>
          <td>
          </td>
          <td>22 (&ndash;8 size, +2 Dex, +18 natural), touch 4,
flat-footed
20</td>
        </tr>
        <tr className="odd-row">
          <td><b>Base Attack/Grapple:</b></td>
          <td>+12/+31</td>
          <td>
          </td>
          <td>+24/+50</td>
        </tr>
        <tr>
          <td><b>Attack:</b></td>
          <td>Bite +15 melee (2d8+10 plus poison)</td>
          <td>
          </td>
          <td>Bite +26 melee (4d6+15 plus poison)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Full Attack:</b></td>
          <td>Bite +15 melee (2d8+10 plus poison)</td>
          <td>
          </td>
          <td>Bite +26 melee (4d6+15 plus poison)</td>
        </tr>
        <tr>
          <td><b>Space/Reach:</b></td>
          <td>20 ft./15 ft.</td>
          <td>
          </td>
          <td>40 ft./30 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><b>Special Attacks:</b></td>
          <td>Poison, web</td>
          <td>
          </td>
          <td>Poison, web</td>
        </tr>
        <tr>
          <td><b>Special Qualities:</b></td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
          <td>
          </td>
          <td>Darkvision 60 ft., tremorsense 60 ft., vermin traits</td>
        </tr>
        <tr className="odd-row">
          <td><b>Saves:</b></td>
          <td>Fort +12, Ref +8, Will +5</td>
          <td>
          </td>
          <td>Fort +20, Ref +12, Will +10</td>
        </tr>
        <tr>
          <td><b>Abilities:</b></td>
          <td>Str 25, Dex 17, Con 14, Int &mdash;, Wis 10, Cha 2</td>
          <td>
          </td>
          <td>Str 31, Dex 15, Con 14, Int &mdash;, Wis 10, Cha 2</td>
        </tr>
        <tr className="odd-row">
          <td><b>Skills:</b></td>
          <td>Climb +14, Hide &ndash;5*, Jump +7*, Spot +4*</td>
          <td>
          </td>
          <td>Climb +16, Hide &ndash;10*, Jump +10*, Spot +7*</td>
        </tr>
        <tr>
          <td><b>Feats:</b></td>
          <td>&mdash;</td>
          <td>
          </td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td><b>Environment:</b></td>
          <td>Temperate forests</td>
          <td>
          </td>
          <td>Temperate forests</td>
        </tr>
        <tr>
          <td><b>Organization:</b></td>
          <td>Solitary</td>
          <td>
          </td>
          <td>Solitary</td>
        </tr>
        <tr className="odd-row">
          <td><b>Challenge Rating:</b></td>
          <td>8</td>
          <td>
          </td>
          <td>11</td>
        </tr>
        <tr>
          <td><b>Treasure:</b></td>
          <td>1/10 coins; 50% goods; 50% items</td>
          <td>
          </td>
          <td>1/10 coins; 50% goods; 50% items</td>
        </tr>
        <tr className="odd-row">
          <td><b>Advancement:</b></td>
          <td>17&ndash;31 HD (Gargantuan)</td>
          <td>
          </td>
          <td>33&ndash;60 HD (Colossal)</td>
        </tr>
        <tr>
          <td className="last-row"><b>Level Adjustment:</b></td>
          <td className="last-row">&mdash;</td>
          <td className="last-row">&nbsp;</td>
          <td className="last-row">&mdash;</td>
        </tr>
      </tbody>
    </table>
    <p>All monstrous spiders are aggressive predators that use their
poisonous bites to subdue or kill prey.</p>
    <p>Monstrous spiders come in two general types: hunters and
web-spinners. Hunters rove about, while web-spinners usually attempt to
trap prey. Hunting spiders can spin webs to use as lairs, but cannot
use their webs as weapons the way web-spinners can. A hunting spider
has a base land speed 10 feet faster than the figures given in the
statistics blocks.</p>
    <p><b>Poison (Ex)</b>: A monstrous spider has a poisonous bite.
The details vary by the spider&rsquo;s size, as shown on the table
below. The
save DCs are Constitution-based. The indicated damage is initial</p>
    <p>and secondary damage.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Size</th>
          <th style={{
            "width": "75px"
          }}>Fort DC</th>
          <th style={{
            "width": "75px"
          }}>Damage</th>
        </tr>
        <tr className="odd-row">
          <td>Tiny</td>
          <td>10</td>
          <td>1d2 Str</td>
        </tr>
        <tr>
          <td>Small</td>
          <td>10</td>
          <td>1d3 Str</td>
        </tr>
        <tr className="odd-row">
          <td>Medium</td>
          <td>12</td>
          <td>1d4 Str</td>
        </tr>
        <tr>
          <td>Large</td>
          <td>13</td>
          <td>1d6 Str</td>
        </tr>
        <tr className="odd-row">
          <td>Huge</td>
          <td>16</td>
          <td>1d8 Str</td>
        </tr>
        <tr>
          <td>Gargantuan</td>
          <td>20</td>
          <td>2d6 Str</td>
        </tr>
        <tr className="odd-row">
          <td>Colossal</td>
          <td>28</td>
          <td>2d8 Str</td>
        </tr>
      </tbody>
    </table>
    <p><b>Web (Ex)</b>: Both types of monstrous spiders often wait in
their webs or in trees, then lower themselves silently on silk strands
and leap onto prey passing beneath. A single strand is strong enough to
support the spider and one creature of the same size.</p>
    <p>Web-spinners can throw a web eight times per day. This is
similar to an attack with a net but has a maximum range of 50 feet,
with a range increment of 10 feet, and is effective against targets up
to one size category larger than the spider.</p>
    <p>An entangled creature can escape with a successful Escape
Artist check or burst it with a Strength check. Both are standard
actions whose DCs are given in the table below. The check DCs are
Constitution-based, and the Strength check DC includes a +4 racial
bonus. </p>
    <p>Web-spinners often create sheets of sticky webbing from 5 to
60 feet square, depending on the size of the spider. They usually
position these sheets to snare flying creatures but can also try to
trap prey on the ground. Approaching creatures must succeed on a DC 20
Spot check to notice a web; otherwise they stumble into it and become
trapped as though by a successful web attack. Attempts to escape or
burst the webbing gain a +5 bonus if the trapped creature has something
to walk on or grab while pulling free. Each 5-foot section has the hit
points given on the table, and sheet webs have damage reduction
5/&mdash;.</p>
    <p>A monstrous spider can move across its own web at its climb
speed and can pinpoint the location of any creature touching its web.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "100px"
          }}>Size</th>
          <th style={{
            "width": "75px"
          }}>Escape
Artist DC</th>
          <th style={{
            "width": "75px"
          }}>Break DC</th>
          <th style={{
            "width": "75px"
          }}>Hit Points</th>
        </tr>
        <tr className="odd-row">
          <td>Tiny</td>
          <td>10</td>
          <td>14</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Small</td>
          <td>10</td>
          <td>14</td>
          <td>4</td>
        </tr>
        <tr className="odd-row">
          <td>Medium</td>
          <td>12</td>
          <td>16</td>
          <td>6</td>
        </tr>
        <tr>
          <td>Large</td>
          <td>13</td>
          <td>17</td>
          <td>12</td>
        </tr>
        <tr className="odd-row">
          <td>Huge</td>
          <td>16</td>
          <td>20</td>
          <td>14</td>
        </tr>
        <tr>
          <td>Gargantuan</td>
          <td>20</td>
          <td>24</td>
          <td>16</td>
        </tr>
        <tr className="odd-row">
          <td>Colossal</td>
          <td>28</td>
          <td>32</td>
          <td>18</td>
        </tr>
      </tbody>
    </table>
    <p><b>Tremorsense (Ex)</b>: A monstrous spider can detect and
pinpoint any creature or object within 60 feet in contact with the
ground, or within any range in contact with the spider&rsquo;s webs.</p>
    <p><b>Skills</b>: Monstrous spiders have a +4 racial bonus on
Hide and Spot checks and a +8 racial bonus on Climb checks. A monstrous
spider can always choose to take 10 on Climb checks, even if rushed or
threatened. Monstrous spiders use either their Strength or Dexterity
modifier for Climb checks, whichever is higher.</p>
    <p>*Hunting spiders have a +10 racial bonus on Jump checks and a
+8 racial bonus on Spot checks. Web-spinning spiders have a +8 racial
bonus on Hide and Move Silently checks when using their webs.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      